import React from "react";
import {
	 Box
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";

const Header = () => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>

			<div className={classes.logo}>
				<img src={require("../../../../assets/logos/logo-full.png").default}/>
			</div>

		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		height: 60,
		backgroundColor: "white",

		padding: "12px 24px",
		boxSizing: "border-box"
	},

	logo: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "center",
		height: "100%",

		"& img": {
			height: 26
		}
	}
}))

export default React.memo(Header)