import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,

	Grid,
	IconButton
} from "@mui/material";
import {
	Close as CloseIcon
} from "@mui/icons-material";
import ImageGallery from "react-image-gallery";
import {VideoPreview} from "../index";
import {isIos} from "../../common/Platform";

class DialogImagesVideos extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			files: [],

			activeIndex: 0,

			isOpen: false
		}
	}

	open = ({ files, activeIndex }) => {
		files = files.map((file) => {
			const isVideo = Boolean((file?.mimetype||"").includes("video"));
			if (isVideo) {
				return {
					original: file.fileUrl || file?.url,
					thumbnail: file.fileUrl || file?.url,
					renderItem: this.renderVideo.bind(this, file),
					renderThumbInner: this.videoThumbInner.bind(this, file)
				}
			}
			return {
				original: file.fileUrl || file?.url,
				thumbnail: file.fileUrl || file?.url,
			}
		})
		console.log('files: ', files);

		this.setState({
			files,
			activeIndex,

			isOpen: true
		})
	}
	close = () => {
		this.setState({
			isOpen: false
		})
	}

	videoThumbInner = (videoItem) => {
		return (
			<div style={{ maxWidth: 90, maxHeight: 60, overflow: "hidden" }}>
				<VideoPreview
					file={videoItem}
				/>
			</div>
		)
	}
	renderVideo = (item) => {
		return (
			<div>
				<video autoPlay={false} width="100%" controls>
					<source src={item.fileUrl || item?.url} />
				</video>
			</div>
		);
	}

	render () {
		const {
			files,
			isOpen,
			activeIndex,
		} = this.state;

		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="sm"
				onClose={this.close}
			>
				<DialogTitle>
					<Grid container justifyContent="flex-end">
						<Grid item>
							<IconButton onClick={this.close}>
								<CloseIcon/>
							</IconButton>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent>
					<ImageGallery
						items={files}
						startIndex={activeIndex}
						showPlayButton={false}
						showIndex={true}
					/>
				</DialogContent>
			</Dialog>
		)
	}
}

export default DialogImagesVideos
