import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,

	Box,
	Grid,
	Button,
	TextField,
	Typography
} from "@mui/material";
import {
	Notification,
	notificationTypes
} from "../../../../../common/Notification";

class DialogConfirmOrder extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			code: "",

			onSubmit: null,

			isOpen: false
		}
	}

	open = ({ onSubmit }) => {
		this.setState({
			onSubmit,

			isOpen: true
		})
	}
	close = () => {
		this.setState({
			code: "",
			isOpen: false
		})
	}

	changeCode = ({ target }) => {
		const { name, value } = target;
		this.setState({
			code: value
		})
	}

	submit = () => {
		if (!this.state.code) {
			Notification({
				type: notificationTypes.error,
				message: "Введите код"
			})

			return
		}

		this.state.onSubmit(this.state.code);
	}

	render () {
		const {
			code,

			isOpen
		} = this.state;

		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle>
					<Typography variant="h3">Подтверждение</Typography>
				</DialogTitle>

				<DialogContent>
					<Box pt={1} mb={2}>
						<TextField
							value={code}
							fullWidth
							variant="outlined"
							placeholder="Введите код"
							helperText="Введите код из получения сообщения о заказе"
							onChange={this.changeCode}
						/>
					</Box>

					<Grid container spacing={{ xs: 1, lg: 2 }}>
						<Grid item xs={12} lg={6}>
							<Button
								variant="outlined"
								fullWidth
								onClick={this.close}
							>Закрыть</Button>
						</Grid>
						<Grid item xs={12} lg={6}>
							<Button
								variant="contained"
								fullWidth
								onClick={this.submit}
							>Подтвердить</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		)
	}
}

export default DialogConfirmOrder