import React from 'react';
import {compose} from 'recompose';
import {connect} from 'react-redux';

// Routes
import ClientRoutes from "./Client";

class Router extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true
		};
	}

	render() {
		return (
			<ClientRoutes/>
		)
	}
}

export default compose(
	connect(
		state => ({  }),
		dispatch => ({  }),
	),
)(Router);
