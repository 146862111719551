import React from "react";
import {isIos} from "../../common/Platform";

const VideoPreview = ({ onClick, file }) => {
	if (isIos()) {
		let type = file?.mimetype;
		if (type === "video/quicktime") {
			type = "video/mp4";
		}

		return (
			<video
				autoPlay={false}
				onClick={onClick}
				muted

				style={{
					maxWidth: "100%",
					maxHeight: "100%"
				}}
			>
				<source
					src={file.fileUrl}
					type={type}
				/>
			</video>
		)
	}

	return (
		<video
			src={file.fileUrl}
			onClick={onClick}
			autoPlay={false}
			muted

			style={{
				maxWidth: "100%",
				maxHeight: "100%"
			}}
		/>
	)
}

export default VideoPreview
