import React from "react";
import {Box, Grid,} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {VideoPreview} from "../../../../../components";

const WorkList = (props) => {
	const {
		data,
		onOpenDialogImagesVideos,
		onOpenDialogImagesMessage,
		isFilesLoading
	} = props;
	const classes = useStyles();

	return (
		<Grid className={classes.root} container spacing={2}>
			{data.map((order) => (
				<Grid key={`order-${order.id}`} item xs={12} lg={6}>
					<OrderCard
						order={order}
						onOpenDialogImagesVideos={onOpenDialogImagesVideos}
						onOpenDialogImagesMessage={onOpenDialogImagesMessage}
						isFilesLoading={isFilesLoading}
					/>
				</Grid>
			))}
		</Grid>
	)
}
const OrderCard = (props) => {
	const {
		order,
		isFilesLoading,
		onOpenDialogImagesVideos,
		onOpenDialogImagesMessage
	} = props;
	const classes = useStyles();

	const handleOpenDialogImagesMessage = (index) => {
		onOpenDialogImagesMessage({
			index,
			files: order.pictures || [],
			message: order?.description || ""
		})
	}

	return (
		<Box className={classes.orderCard}>
			<Box className={classes.orderCardHead}>
				<div
					className={classes.orderCardName}
					dangerouslySetInnerHTML={{
						__html: order.description
					}}
				/>
			</Box>
			<Box className={classes.orderCardBody}>
				{Boolean((order.pictures || []).length > 0) && (
					<Grid className={classes.files} container spacing={1}>
						{(order.pictures || []).map((file, index) => {
							const isVideo = Boolean((file?.mimetype || '').includes("video"));
							if (!!isVideo) {
								return (
									<Grid item key={`work-video-file-${file.fileId}`}>
										<VideoPreview
											file={file}
											onClick={handleOpenDialogImagesMessage.bind(this, index)}
											_onClick={onOpenDialogImagesVideos.bind(this, order.pictures || [], index)}
										/>
									</Grid>
								)
							}
							return (
								<Grid item key={`work-photo-file-${file.fileId}`}>
									<img
										src={file.fileUrl}
										onClick={handleOpenDialogImagesMessage.bind(this, index)}
										_onClick={onOpenDialogImagesVideos.bind(this, order.pictures || [], index)}
									/>
								</Grid>
							)
						})}
					</Grid>
				)}
			</Box>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		"@media(max-width: 1199px)": {
			paddingBottom: 100
		}
	},
	prices: {

		"@media(max-width: 1199px)": {
			position: "fixed",
			bottom: 0,
			left: 0,
			right: 0,
			backgroundColor: "white",
			borderRadius: "8px 8px 0 0",
			padding: 12,
			boxShadow: "0px 2px 8px rgb(142 150 168 / 24%)",

			"& .MuiTypography-root": {
				display: "flex",
				flexDirection: "row",
				flewWrap: "nowrap",

				fontSize: 14,
				lineHeight: "14px",
				whiteSpace: "nowrap"
			},
			"& .MuiGrid-root > *": {
				width: "100%",

				"& span": {
					display: "block",
					flex: 1,
					borderBottom: "1px dotted #647083",
					margin: "0 4px"
				}
			}
		}
	},

	orderCard: {
		display: "flex",
		flexDirection: "column",
		height: "100%",

		padding: 20,
		boxSizing: "border-box",
		boxShadow: "0px 2px 8px rgba(142, 150, 168, 0.24)",
		backgroundColor: "white",
		borderRadius: 4,

		"@media(max-width: 1199px)": {
			padding: 12,
		}
	},
	orderCardHead: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 12
	},
	orderCardBody: {
		marginBottom: 16
	},
	orderCardFooter: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
		justifyContent: "flex-end",
		marginTop: "auto",

		"& > *": {
			marginTop: "4px"
		}
	},
	orderCardName: {
		fontSize: 20,
		lineHeight: "24px",
		color: "black",
		fontWeight: "500",

		"@media(max-width: 1199px)": {
			fontSize: 18,
			lineHeight: "20px",
		}
	},

	orderCardEstimateTitle: {
		fontSize: 16,
		lineHeight: "18px",
		marginBottom: 6
	},
	orderCardEstimate: {
		fontSize: 14,
		lineHeight: "18px",
	},

	filesTitle: {
		fontSize: 16,
		lineHeight: "18px",
		marginBottom: 6
	},
	files: {
		"& > * > *": {
			width: 60,
			height: 60,
			borderRadius: 4,
			overflow: "hidden",
			border: "1px solid red",
			objectFit: "contain"
		}
	},

	orderPriceBig: {
		fontSize: 20,
		lineHeight: "20px",
		color: "rgba(0,0,0,0.7)",

		"& span": {
			color: "black"
		}
	},
	orderPriceSmall: {
		fontSize: 16,
		lineHeight: "18px",
		color: "rgba(0,0,0,0.7)",

		"& span": {
			color: "black"
		}
	},
	warning: {
		color: 'red',
		marginTop: '5px',
		fontSize: '14px'
	}
}));

export default React.memo(WorkList)
