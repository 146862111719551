import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const UserInfo = (props) => {
    const {
        client
    } = props;
    const classes = useStyles();

    const getClientName = () => {
        if (client.isLegal) {
            return (
                <>
                    <Grid item>
                        Компания - { client?.companyName || 'Нет данных о компании' }
                    </Grid>
                </>
            );
        }

        return (
            <>
                <Grid item>
                    Фамилия - <b>{ client?.lastName || "Нет данных о фамилии" }</b>;
                </Grid>
                <Grid item>
                    Имя - <b>{ client?.firstName || "Нет данных об имени" }</b>;
                </Grid>
            </>
        );
    };

    return (
        <>
            <Box mb={ 1 }>
                <Typography variant="h3" textAlign="left">Информация о клиенте</Typography>
            </Box>
            <Grid className={ classes.root } container spacing={ 1 }>
                { getClientName() }
            </Grid>
        </>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        "& b": {
            fontWeight: "500",
            textDecoration: "underline"
        }
    }
}));

export default React.memo(UserInfo)
