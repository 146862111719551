import React, {Component} from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	Typography
} from "@mui/material";
import {withStyles} from "@mui/styles";
import {ExpandMore as ExpandIcon} from '@mui/icons-material';
import {
	DialogConfirmOrder as DialogConfirmOrderComponent,
	RecommendationList as RecommendationListComponent,
	WorkList as WorkListComponent,
	ApplicationInfo as ApplicationInfoComponent,
	UserInfo as UserInfoComponent,
} from "./components";
import {
	DialogImagesVideos,
	DialogImagesMessage
} from "../../../components";
import {Notification, notificationTypes} from "../../../common/Notification";
import agent from "../../../agent/agent";
import {getFileFromUrl} from "../../../common/Files";
import orderStatus from "../../../constants/orderStatus";

class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			order: {},

			orderId: props?.match?.params?.orderId || null,
			confirmationId: null,

			enabledRecommendations: [],

			isLoad: true,
			isFilesLoading: true,
			isFail: false,
			isShowBackdrop: false
		};

		this.refDialogImagesVideos = React.createRef();
		this.refDialogConfirmOrder = React.createRef();
		this.refDialogImagesMessage = React.createRef();
	}

	componentDidMount = async () => {
		await this.getOrder();
	}

	getOrder = async () => {
		const {orderId} = this.state;

		let response = await agent.get(`/orders/client/${orderId}`).then((res) => {
			return res.data?.order || null
		}).catch((err) => {
			return {error: err.response || true}
		})
		if (response.error) {
			this.setState({
				isLoad: false,
				isFail: true
			})

			return
		}


		response.orderServices = [...response.orderServices].sort((a, b) => {
			if (a.createdAt > b.createdAt) {
				return 1
			}
			if (a.createdAt < b.createdAt) {
				return -1
			}
			return 0
		})
		this.setState({
			order: response,
			isLoad: false
		})


		response.orderServices = await Promise.all(([...response.orderServices]).map(async (service) => {
			service.pictures = await Promise.all((service.pictures || []).map(async (file) => {
				const uri = [process.env.REACT_APP_HOST_API, '/static/service/', file.name].join("");
				return {
					fileUrl: uri,
					mimetype: file.mimetype,
				};
			}));
			return service
		}));

		this.setState({
			order: response,
			isFilesLoading: false
		})
	}

	// Подтверждение заказа
	createConfirmation = async () => {
		this.setState({isShowBackdrop: true});

		const response = await agent.post(`/orders/client/${this.state.orderId}/create-confirmation`).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		if (response.error) {
			this.setState({isShowBackdrop: false})

			Notification({
				message: response.error?.data?.details || "Ошибка сервера",
				type: notificationTypes.error
			})

			return
		}

		this.refDialogConfirmOrder.current.open({
			onSubmit: this.orderConfirmation.bind(this)
		})
		this.setState({
			isShowBackdrop: false,
			confirmationId: response.confirmationId
		});
	}
	orderConfirmation = async (code) => {
		this.setState({isShowBackdrop: true});

		const response = await agent.post(`/orders/client/${this.state.orderId}/confirm`, {
			"confirmationId": this.state.confirmationId,
			"code": code,
			"enabledRecommendations": this.state.enabledRecommendations,
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		if (response.error) {
			this.setState({
				isShowBackdrop: false,
			});

			Notification({
				message: response.error?.data?.details || "Ошибка сервера",
				type: notificationTypes.error
			})

			return
		}

		await this.getOrder();
		this.refDialogConfirmOrder.current.close();
		this.setState({
			isShowBackdrop: false,
			enabledRecommendations: []
		});
		Notification({
			message: 'Рекомендации успешно согласованы',

		})
	}

	openDialogImagesVideos = (files, activeIndex) => {
		this.refDialogImagesVideos.current.open({
			files,
			activeIndex
		})
	}
	openDialogImagesMessage = ({ files, index, message }) => {
		this.refDialogImagesMessage.current.open({ files, index, message });
	}

	isActionEnabled = () => {
		const {
			order,
			isFail
		} = this.state;

		if (!isFail && order.recommendationsForClient && order.recommendationsForClient.length > 0) {
			const notConfirmedRecommendation = order.recommendationsForClient.find((recommendation) => {
				if (recommendation.confirmedAt === null) {
					return recommendation;
				}
			});

			return notConfirmedRecommendation !== undefined;
		}

		return false;
	};

	handleChangeEnabledRecommendations = (id) => {
		const {enabledRecommendations} = this.state;
		let newEnabledRecommendations = []

		if (enabledRecommendations.includes(id)) {
			newEnabledRecommendations = enabledRecommendations.filter((recommendation) => recommendation !== id);
		} else {
			newEnabledRecommendations = [...enabledRecommendations, id];
		}

		this.setState({
			enabledRecommendations: newEnabledRecommendations,
		});
	};

	render() {
		const {
			classes
		} = this.props;
		const {
			order,

			enabledRecommendations,

			isLoad,
			isFail,
			isShowBackdrop
		} = this.state;
		const status = orderStatus[order.status] || {};

		return (
			<Box>

				<VisibleContent visible={Boolean(!isLoad && !isFail)}>
					<Box mb={1}>
						<Grid container alignItems="center" justifyContent="space-between" spacing={1}>
							<Grid item>
								<Typography variant="h3">
									Статус заявки: <span style={{color: status.color}}>{status.label}</span>
								</Typography>
							</Grid>
							<Grid item xs={12} lg="auto">
								{this.isActionEnabled() && (
									<Button
										variant="contained"
										fullWidth
										disabled={enabledRecommendations.length <= 0}
										onClick={this.createConfirmation.bind(this)}
									>
										Подтвердить
									</Button>
								)}
							</Grid>
						</Grid>
					</Box>


					<Box mb={1} className={classes.user}>
						<ApplicationInfoComponent
							application={order}
						/>
					</Box>

					<Box mb={1} className={classes.user}>
						<UserInfoComponent
							client={order.client}
						/>
					</Box>

					<Box className={classes.columnContent}>
						<Box className={classes.root}>
							<RecommendationListComponent
								data={order?.recommendationsForClient || []}
								enabledRecommendations={enabledRecommendations}
								onEditEnabledRecommendations={this.handleChangeEnabledRecommendations}
								onOpenDialogImagesVideos={this.openDialogImagesVideos}
								onOpenDialogImagesMessage={this.openDialogImagesMessage}
								isFilesLoading={this.state.isFilesLoading}
							/>
						</Box>
						<Accordion className={classes.root}>
							<AccordionSummary
								sx={{padding: 0}}
								expandIcon={<ExpandIcon/>}
								aria-controls="work-list-accordion-content"
								id="work-list-accordion-header"
							>
								<Typography variant="h3">Перечень выполненных работ</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<WorkListComponent
									data={order.orderServices || []}
									onOpenDialogImagesVideos={this.openDialogImagesVideos}
									onOpenDialogImagesMessage={this.openDialogImagesMessage}
									isFilesLoading={this.state.isFilesLoading}
								/>
							</AccordionDetails>
						</Accordion>
					</Box>
				</VisibleContent>
				<VisibleContent visible={Boolean(isLoad)}>
					<Box className={classes.loading}>
						<img src={require("../../../assets/svg/loading/loading.svg").default}/>
					</Box>
				</VisibleContent>
				<VisibleContent visible={Boolean(isFail)}>
					<Box className={classes.error}>
						Заказ не найден
					</Box>
				</VisibleContent>


				<DialogImagesVideos
					ref={this.refDialogImagesVideos}
				/>
				<DialogConfirmOrderComponent
					ref={this.refDialogConfirmOrder}
				/>
				<DialogImagesMessage
					ref={this.refDialogImagesMessage}
				/>


				<Backdrop open={isShowBackdrop}>
					<CircularProgress/>
				</Backdrop>

			</Box>
		);
	}
}

const VisibleContent = React.memo(({visible, children}) => {
	if (!visible) {
		return null
	}

	return children || null
})

const styles = {
	root: {
		margin: 0,
		padding: 20,
		boxSizing: "border-box",
		backgroundColor: "white",
		borderRadius: 14,
		border: "1px solid #F0F0F0",

		'&.Mui-expanded': {
			margin: 0,
		},

		"@media(max-width: 1199px)": {
			padding: '0',
			border: "none",
			backgroundColor: "transparent",

			'&.MuiAccordion-root': {
				padding: '0 10px',
			},
		}
	},
	user: {
		padding: 20,
		boxSizing: "border-box",
		backgroundColor: "white",
		borderRadius: 14,
		border: "1px solid #F0F0F0",

		"@media(max-width: 1199px)": {
			padding: 12
		}
	},
	columnContent: {
		display: "flex",
		flexDirection: "column",
		gap: "8px",

		"@media(max-width: 1199px)": {
			flexDirection: "column",
		}
	},

	loading: {
		height: 100,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",

		backgroundColor: "white",
		borderRadius: 14,
		border: "1px solid #F0F0F0",

		"& img": {
			maxHeight: 80
		}
	},
	error: {
		fontSize: 20,
		lineHeight: "24px",
		textAlign: "center",
		padding: 20,
		boxSizing: "border-box",
		backgroundColor: "white",
		borderRadius: 14,
		border: "1px solid #F0F0F0",
	},
}
Home = withStyles(styles)(Home)

export default Home
