const _____ = "app/_____"

const initialState = {};

export default function AppState(state = initialState, action = {}) {
    switch (action.type) {


        default:
            return state;
    }
}
