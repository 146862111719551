import React from "react";
import {
	Box,
	Grid,
	Button,
	Typography,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {VideoPreview} from "../index";
import {clipboardWriteText} from "../../common/Clipboard";
import {Notification, notificationTypes} from "../../common/Notification";
import ImageGallery from "react-image-gallery";

class DialogImagesMessage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			files: [],
			index: 0,
			message: "",

			isOpen: false
		};
		this.refImageGallery = React.createRef();
	}

	open = ({ files, index, message }) => {
		const _files = files.map((file) => {
			const isVideo = Boolean((file?.file?.type||file?.mimetype||"").includes("video"));
			const _getFileUrl = () => {
				if (file?.url) {
					return file?.url
				}
				if (file?.fileUrl) {
					return file?.fileUrl
				}
				return URL.createObjectURL(file.file)
			}
			if (isVideo) {
				return {
					file: file,
					original: _getFileUrl(),
					thumbnail: _getFileUrl(),
					renderItem: this.renderVideo.bind(this, file),
					renderThumbInner: this.videoThumbInner.bind(this, file)
				}
			}
			return {
				file: file,
				original: _getFileUrl(),
				thumbnail: _getFileUrl()
			}
		});
		this.setState({
			index,
			message,

			files: _files,
			isOpen: true
		})
	}
	close = () => {
		this.setState({
			files: [],
			index: 0,
			message: "",

			isOpen: false
		})
	}

	videoThumbInner = (videoItem) => {
		return (
			<div>
				<VideoPreview
					file={videoItem}
				/>
			</div>
		)
	}
	renderVideo = (file) => {
		const link = file?.fileUrl || URL.createObjectURL(file?.file) || "";
		return (
			<div>
				<video autoPlay={false} width="100%" controls>
					<source src={link} />
				</video>
			</div>
		);
	}
	shareItem = async () => {
		const { files } = this.state;
		const currentIndex = this.refImageGallery.current.state?.currentIndex || 0;
		const element = files[currentIndex];
		const fileUrl = element?.original;

		clipboardWriteText(fileUrl);
		Notification({
			type: notificationTypes.success,
			message: "Ссылка на файл успешно скопирована"
		})
	}

	changeSlide = (type) => {}
	prevFile = () => {
		this.refImageGallery.current.slideLeft();
	}
	nextFile = () => {
		this.refImageGallery.current.slideRight();
	}

	render () {
		const {
			files,
			index,
			message,
			isOpen
		} = this.state;
		const {
			classes
		} = this.props;

		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="md"
			>
				<DialogContent>
					<Box className={classes.sectionFiles}>
						<ImageGallery
							ref={this.refImageGallery}
							items={files}
							startIndex={index}
							showIndex={false}
							showPlayButton={false}
						/>
						<Box mt={1}/>
						<Grid container justifyContent="space-between">
							<Grid item>
								<Button
									variant="contained"
									className={classes.sectionButton}
									onClick={this.shareItem}
								>Копировать</Button>
							</Grid>
							<Grid item>
								<Grid container spacing={1}>
									<Grid item>
										<Button
											variant="outlined"
											className={classes.sectionButton}
											onClick={this.prevFile}
										>Предыдущая</Button>
									</Grid>
									<Grid item>
										<Button
											variant="contained"
											className={classes.sectionButton}
											onClick={this.nextFile}
										>Следующая</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
					<Box className={classes.sectionMessage}>
						<div className="--title">Описание</div>
						<div
							className="--message"
							dangerouslySetInnerHTML={{__html: message}}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Grid container justifyContent="flex-end">
						<Grid item>
							<Button
								size="small"
								color="primary"
								variant="outlined"
								className={classes.actionButton}
								onClick={this.close}
							>Закрыть</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}

const styles = {
	title: {
		textAlign: "left"
	},
	sectionFiles: {
		display: "flex",
		flexDirection: "column",

		"& .image-gallery": {
			display: "flex",
			flexDirection: "column",
			height: "100%"
		},
		"& .image-gallery-slide > *, & .image-gallery-slide > * > *": {
			width: "100%", height: "100%",
			objectFit: "contain",
			objectPosition: "center"
		},
		"& .image-gallery-slides": {
			background: "#F4F6F8",
			borderRadius: 10,

		},
		"& .image-gallery-slide-wrapper": {
			height: "calc(100vh - 250px)",
		},
		"& .image-gallery-swipe, & .image-gallery-slides, & .image-gallery-slide": {
			display: "flex",
			flexDirection: "column",
			height: "100%"
		},
		"& .image-gallery-thumbnails-wrapper": {
			display: "none"
		},
		"& .image-gallery-icon": {
			display: "none"
		}
	},
	sectionButton: {
		height: "auto",
		padding: "8px 16px",
		borderRadius: "8px",

		fontSize: 14,
		lineHeight: 1
	},
	sectionMessage: {
		marginTop: 24,
		'& .--title': {
			marginBottom: 6,
			fontSize: "12px",
			lineHeight: "130%",
			letterSpacing: "-0.02em",
			color: "#647083",
		},
		'& .--message': {
			fontWeight: "600",
			fontSize: "16px",
			lineHeight: "130%",
			letterSpacing: "-0.02em",
			color: "#152435",
		},
	},
	actionButton: {
		height: "auto",
		padding: "2px 24px",
		borderRadius: "8px"
	}
};
DialogImagesMessage = withStyles(styles)(DialogImagesMessage);

export default DialogImagesMessage
