import React from "react";
import {Switch, Redirect} from "react-router-dom";
import {
    RouteWithLayout
} from "../components";
import {
    ClientLayout
} from "../layouts";
import {
    ClientHome
} from "../pages";


const pages = [
    {
        path: '/order/:orderId',
        component: ClientHome,
        layout: ClientLayout,
        rules: [],
        exact: true
    }
];

const LandingRoutes = () => {
    return (
        <Switch>
            {
                pages.map((page, idx) => (
                    <RouteWithLayout
                        key={'page-' + idx}
                        {...page}
                    />
                ))
            }
            <Redirect to="/order/null"/>
        </Switch>
    );
};

export default LandingRoutes;
