import React from "react";
import {
	Box
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	Header
} from "./components";

class Client extends React.PureComponent {
	render () {
		const {
			classes
		} = this.props;

		return (
			<Box className={classes.root}>

				<Header/>

				<Box className={classes.body}>
					{ this.props.children }
				</Box>

			</Box>
		)
	}
}

const styles = {
	root: {
		display: "flex",
		flexDirection: "column",

		height: "100%",
		overflow: "hidden",
		backgroundColor: "#F2F2F6"
	},
	body: {
		padding: "12px 24px",
		boxSizing: "border-box",
		height: "calc(100% - 60px)",
		overflow: "auto",

		"@media(max-width: 1199px)": {
			padding: 12
		}
	},
}
Client = withStyles(styles)(Client)

export default Client